import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { PayloadActionCreator } from "@reduxjs/toolkit";
import { default as React, useCallback } from "react";
import { connect } from "react-redux";
import { CreateMazeType } from "../../grids/CreateMazeType";
import { GeneratorType } from "../../grids/GeneratorType";
import implementations, { MazeKinds } from "../../MazeKinds";
import { algorithmType } from "../../state/mazeAlgorithms";
import { maskingSelector } from "../../state/mazeFunctions";
import { create } from "../../state/mazeSlice";
import { RootState } from "../../state/store";
import {
  CreateDialogState,
  setGridColumns,
  setGridRows,
  setMasking,
  setMazeKind,
  setMazeType,
  setNewDialogOpen,
  validAlgorithms,
  setBraidPercent,
  setBraid,
} from "./createSlice";

type CreateProps = {
  createMaze: PayloadActionCreator<CreateMazeType>;
  state: CreateDialogState;
  setMazeType: PayloadActionCreator<GeneratorType>;
  setMazeKind: PayloadActionCreator<MazeKinds>;
  setGridColumns: PayloadActionCreator<number | undefined>;
  setMasking: PayloadActionCreator<boolean>;
  setGridRows: PayloadActionCreator<number | undefined>;
  validAlgorithms: algorithmType[];
  disabled: boolean;
  newDialogOpen: boolean;
  setNewDialogOpen: PayloadActionCreator<boolean>;
  setBraidPercent: PayloadActionCreator<number | undefined>;
  setBraid: PayloadActionCreator<boolean>;
};

export const parseIntOrUseExisting = (value: string) => {
  const num = parseInt(value, 10);
  if (isNaN(num) || num < 1) {
    return undefined;
  } else {
    return num;
  }
};

export const CreateMaze = ({
  createMaze,
  setMazeType,
  setGridColumns,
  setGridRows,
  setMazeKind,
  setMasking,
  validAlgorithms,
  state,
  disabled,
  newDialogOpen,
  setNewDialogOpen,
  setBraid,
  setBraidPercent,
}: CreateProps) => {
  const { mazeType, gridColumns, gridRows, mazeKind, masking } = state;

  const onCreateClick = useCallback(() => setNewDialogOpen(true), [
    setNewDialogOpen,
  ]);

  const handleCancel = useCallback(() => {
    setNewDialogOpen(false);
  }, [setNewDialogOpen]);

  const handleCreate = useCallback(() => {
    setNewDialogOpen(false);
    createMaze({
      mazeKind,
      mazeType,
      rows: gridRows!,
      columns: gridColumns!,
      masking: masking,
      braid: state.braid,
      braidPercent: state.braidPercent,
    });
  }, [
    createMaze,
    mazeKind,
    mazeType,
    gridRows,
    gridColumns,
    masking,
    setNewDialogOpen,
    state,
  ]);

  const onUpdateMazeType = useCallback(
    (e: any) => {
      setMazeType(e.target.value as GeneratorType);
    },
    [setMazeType]
  );
  const onUpdateMazeKind = useCallback(
    (e: any) => {
      setMazeKind(e.target.value as MazeKinds);
    },
    [setMazeKind]
  );
  const onUpdateMasking = useCallback(
    (e: any) => {
      setMasking(e.target.checked);
    },
    [setMasking]
  );

  const onUpdateBraid = useCallback(
    (e: any) => {
      setBraid(e.target.checked);
    },
    [setBraid]
  );

  const onUpdateBraidPercent = useCallback(
    (e: any) => {
      setBraidPercent(parseIntOrUseExisting(e.target.value as string));
    },
    [setBraidPercent]
  );

  const onUpdateRows = useCallback(
    (e: any) => {
      setGridRows(parseIntOrUseExisting(e.target.value as string));
    },
    [setGridRows]
  );

  const onUpdateColumns = useCallback(
    (e: any) => {
      setGridColumns(parseIntOrUseExisting(e.target.value as string));
    },
    [setGridColumns]
  );

  return (
    <React.Fragment>
      {!disabled && (
        <IconButton
          color="inherit"
          aria-label="Create Maze"
          title="Create Maze"
          component="span"
          onClick={onCreateClick}
          id="create"
        >
          <CreateIcon />
        </IconButton>
      )}

      <Dialog
        open={newDialogOpen}
        onClose={handleCancel}
        aria-labelledby="new-dialog-title"
      >
        <DialogTitle id="new-dialog-title">Create Maze</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create a new maze using the specified algorithm and size.
          </DialogContentText>

          <TextField
            id="mazeKind"
            select
            autoFocus
            SelectProps={{
              native: true,
            }}
            label="Maze Kind"
            type="number"
            margin="dense"
            fullWidth
            value={mazeKind}
            onInput={onUpdateMazeKind}
            variant="outlined"
          >
            {Object.values(implementations).map((v) => (
              <option key={v.id} value={v.id}>
                {v.display}
              </option>
            ))}
          </TextField>

          <TextField
            id="generator"
            select
            autoFocus
            SelectProps={{
              native: true,
            }}
            label="Generator"
            type="number"
            margin="dense"
            fullWidth
            value={mazeType}
            onInput={onUpdateMazeType}
            variant="outlined"
          >
            {validAlgorithms.map((e) => (
              <option key={e.value} value={e.value}>
                {e.display}
              </option>
            ))}
          </TextField>

          {implementations[mazeKind].needsRows && (
            <TextField
              id="rows-number"
              label="Rows"
              required
              type="number"
              margin="dense"
              fullWidth
              value={gridRows}
              onInput={onUpdateRows}
              variant="outlined"
              inputProps={{ min: 2, max: 100 }}
              error={!!state.gridRowsError}
              helperText={state.gridRowsError}
            />
          )}
          {implementations[mazeKind].needsColumns && (
            <TextField
              id="columns-number"
              label="Columns"
              type="number"
              required
              margin="dense"
              fullWidth
              value={gridColumns}
              onInput={onUpdateColumns}
              variant="outlined"
              inputProps={{ min: 2, max: 100 }}
              error={!!state.gridColumnsError}
              helperText={state.gridColumnsError}
            />
          )}
          <FormControlLabel
            style={{ display: "block" }}
            control={
              <Switch
                checked={masking}
                onChange={onUpdateMasking}
                name="checkedA"
              />
            }
            label="Edit mask before generating"
          />
          <FormControlLabel
            control={
              <Switch
                checked={state.braid}
                onChange={onUpdateBraid}
                name="braiding"
              />
            }
            label="Braid dead-ends"
          />
          <TextField
            id="brading-percentage"
            label="Percent to braid"
            type="number"
            required={!!state.braid}
            margin="dense"
            fullWidth
            value={state.braidPercent}
            onInput={onUpdateBraidPercent}
            variant="outlined"
            inputProps={{ min: 0, max: 100 }}
            error={state.braid && !!state.braidPercentError}
            helperText={state.braid ? state.braidPercentError : ""}
            disabled={!state.braid}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleCreate}
            color="primary"
            variant="contained"
            disabled={
              !!state.gridColumnsError ||
              !!state.gridRowsError ||
              !!state.braidPercentError
            }
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    validAlgorithms: validAlgorithms(state.createDialog),
    state: state.createDialog,
    disabled: maskingSelector(state),
    newDialogOpen: state.createDialog.newDialogOpen,
  };
};
const mapDispatchToProps = {
  createMaze: create,
  setGridColumns,
  setGridRows,
  setMazeType,
  setMazeKind,
  setMasking,
  setNewDialogOpen,
  setBraidPercent,
  setBraid,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CreateMaze));
