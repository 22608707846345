import { IconButton } from "@material-ui/core";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import { PayloadActionCreator } from "@reduxjs/toolkit";
import React, { useCallback, useRef } from "react";
import { RootState } from "../state/store";
import { error } from "../state/toastSlice";
import read from "../state/readAndHandleErrors";
import { connect } from "react-redux";

export function openMaze(
  reader: FileReader,
  file: File,
  read: (h: string) => void,
  errorCallback: PayloadActionCreator<string>,
  clear: () => void
) {
  reader.onabort = () => {
    clear();
    errorCallback("Reading the file was aborted");
  };
  reader.onerror = () => {
    clear();
    errorCallback("There was an error reading the file");
  };
  reader.onload = () => {
    clear();
    read(reader.result as string);
  };
  reader.readAsText(file);
}
type OpenProps = {
  read: (maze: string) => void;
  errorCallback: PayloadActionCreator<string>;
};

export const OpenMaze = React.memo(({ read, errorCallback }: OpenProps) => {
  const fi = useRef<HTMLInputElement>(null);

  const onDrop = useCallback(
    (e) => {
      e.persist();
      openMaze(
        new FileReader(),
        (e.target as HTMLInputElement).files![0],
        read,
        errorCallback,
        () => {
          e.target && ((e.target as HTMLInputElement).value = "");
        }
      );
    },
    [read, errorCallback]
  );

  const iconClick = useCallback(() => fi.current && fi.current.click(), [fi]);

  return (
    <React.Fragment>
      <input
        ref={fi}
        onInput={onDrop}
        accept=".maze"
        style={{ display: "none" }}
        type="file"
        data-testid="mazeUpload"
      />
      <IconButton
        id="icon-button-open"
        color="inherit"
        aria-label="Open Maze"
        title="Open Maze"
        component="span"
        onClick={iconClick}
      >
        <OpenInBrowserIcon />
      </IconButton>
    </React.Fragment>
  );
});

const mapStateToProps = (state: RootState) => {
  return {};
};
const mapDispatchToProps = {
  read,
  errorCallback: error,
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenMaze);
