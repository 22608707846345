import { GridMazeState } from "./GridMazeState";
import { CellRef, neighbors, maskRef, unmaskRef } from "./Cell";

const maskCell = (s: GridMazeState, ref: CellRef) => {
  const cell = s.cells[ref - 1];

  if (cell.links.length > 0) {
    throw new Error("Cells cannot be masked after they are linked");
  }
  if (cell.masked) {
    cell.masked = false;
    for (let neighbor of neighbors(cell, true)) {
      unmaskRef(cell, neighbor);
      unmaskRef(s.cells[neighbor - 1], cell.coords);
    }
  } else {
    cell.masked = true;
    for (let neighbor of neighbors(cell)) {
      maskRef(cell, neighbor);
      maskRef(s.cells[neighbor - 1], cell.coords);
    }
  }
};

export default maskCell;
