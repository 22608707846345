import { setHighlight } from "./mazeSlice";
import { error } from "./toastSlice";
import { AppThunk } from "./store";
import { Highlight } from "./mazeTypes";
import { validHighlightSelector } from "./mazeFunctions";

const highlight = (highlight: Highlight): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    if (validHighlightSelector(state).indexOf(highlight) >= 0) {
      dispatch(setHighlight(highlight));
    } else {
      dispatch(error(`${highlight} is not currently valid`));
    }
  };
};

export default highlight;
