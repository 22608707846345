import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { PayloadActionCreator } from "@reduxjs/toolkit";
import React from "react";
import { ToastState } from "../state/toastSlice";

export const Alert = React.memo((props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
});

type ToasterProps = {
  toast: ToastState;
  autoHideDuration?: number;
  hide: PayloadActionCreator<void>;
};

export const Toaster = React.memo(
  ({ hide, toast, autoHideDuration }: ToasterProps) => {
    return (
      <Snackbar
        open={toast.display}
        autoHideDuration={autoHideDuration || 6000}
        onClose={hide}
      >
        <Alert onClose={hide} severity={toast.severity}>
          {toast.message}
        </Alert>
      </Snackbar>
    );
  }
);
