import { BaseCell } from "../../grids/Cell";
import { Grid, GridDirections } from "../../grids/Grid";
import MazeEngine, { Walls } from "./MazeEngine";

const cellSize = 25;

export function findCellLeft(cell: BaseCell<unknown>) {
  return cell.column * cellSize;
}

export function findCellTop(cell: BaseCell<unknown>) {
  return cell.row * cellSize;
}

export function length(numCells: number): number {
  return numCells * cellSize;
}

class RectangularMaze extends MazeEngine<Grid, GridDirections> {
  cellSize() {
    return cellSize;
  }
  findCellLeft(cell: BaseCell<unknown>) {
    return findCellLeft(cell);
  }

  findCellTop(cell: BaseCell<unknown>) {
    return findCellTop(cell);
  }

  height() {
    return length(this.grid.description.rows);
  }
  width() {
    return length(this.grid.description.columns);
  }

  calculateWalls(cell: BaseCell<GridDirections>): Walls<GridDirections> {
    const left = findCellLeft(cell);
    const top = findCellTop(cell);
    const right = left + cellSize - 1;
    const bottom = top + cellSize - 1;

    return {
      cell: cell,
      walls: [
        {
          kind: "line",
          neighbor: cell.adjacentRefs.west,
          x1: left,
          y1: bottom,
          x2: left,
          y2: top,
        },
        {
          kind: "line",
          neighbor: cell.adjacentRefs.north,
          x1: left,
          y1: top,
          x2: right,
          y2: top,
        },
        {
          kind: "line",
          neighbor: cell.adjacentRefs.east,
          x1: right,
          y1: top,
          x2: right,
          y2: bottom,
        },
        {
          kind: "line",
          neighbor: cell.adjacentRefs.south,
          x1: right,
          y1: bottom,
          x2: left,
          y2: bottom,
        },
      ],
    };
  }
}

export default RectangularMaze;
