import { Grid } from "./Grid";
import { eachCell } from "./BaseGrid";

import { isLinked } from "./Cell";

export default function writeGrid(grid: Grid): string {
  let maxWidth = 0;
  let maxHeight = 0;
  for (let cell of eachCell(grid)) {
    if (cell.metadata) {
      const lines = cell.metadata.trim().split("\n");
      if (lines.length > maxHeight) {
        maxHeight = lines.length;
      }
      for (let j = 0; j < lines.length; j++) {
        let width = lines[j].trim().length;
        if (width > maxWidth) {
          maxWidth = width;
        }
      }
    }
  }

  const contentWidth = Math.max(maxWidth + 2, 3);
  const contentHeight = Math.max(maxHeight, 1);
  const output = [] as string[];

  const noSouthWall = " ".repeat(contentWidth) + "+";
  const southWall = "-".repeat(contentWidth) + "+";
  const emptyContent = " ".repeat(contentWidth);

  // seed the first row
  output.push("+");
  output.push(southWall.repeat(grid.description.columns));
  output.push("\n");

  for (let row = 0; row < grid.description.rows; row++) {
    const metadataLines = grid
      .getRow(row)
      .map((x) => (x.metadata ? x.metadata.split("\n") : ([] as string[])));
    for (let j = 0; j < contentHeight + 1; j++) {
      //seed the first column
      if (j === contentHeight) {
        output.push("+");
        for (let column = 0; column < grid.description.columns; column++) {
          let cell = grid.getCell(row, column);
          const southPassage =
            cell.adjacentRefs.south && isLinked(cell, cell.adjacentRefs.south);
          if (southPassage) {
            output.push(noSouthWall);
          } else {
            output.push(southWall);
          }
        }
      } else {
        output.push("|");
        for (let column = 0; column < grid.description.columns; column++) {
          let cell = grid.getCell(row, column);
          let cellMetadata = metadataLines[column];
          if (cellMetadata.length <= j) {
            output.push(emptyContent);
          } else {
            output.push(" ");
            output.push(cellMetadata[j]);
            output.push(" ".repeat(contentWidth - cellMetadata[j].length - 1));
          }
          const eastPassage =
            cell.adjacentRefs.east && isLinked(cell, cell.adjacentRefs.east);
          if (eastPassage) {
            output.push(" ");
          } else {
            output.push("|");
          }
        }
      }
      output.push("\n");
    }
  }

  return output.join("").trim();
}
