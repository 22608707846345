import { eachCell, RowColumnGrid } from "./BaseGrid";
import { BaseCell, CellRef } from "./Cell";
import { MazeKinds } from "../MazeKinds";

export type TriangularDirections = {
  north?: CellRef;
  south?: CellRef;
  east?: CellRef;
  west?: CellRef;
};

export type TriangularCell = BaseCell<TriangularDirections>;

export class TriangularGrid extends RowColumnGrid<TriangularDirections> {
  constructor(rows: number, columns: number, cells?: TriangularCell[]) {
    super(MazeKinds.TRIANGULAR, { rows, columns }, cells);
    this.initialize();
  }

  configureCells() {
    for (let cell of eachCell(this)) {
      let [row, col] = [cell.row, cell.column];
      // if column is even, row staggers up, otherwise staggers down

      this.maybeAssignSingleCell(cell, "west", row, col - 1);
      this.maybeAssignSingleCell(cell, "east", row, col + 1);
      if (row % 2 === 0) {
        if (col % 2 === 0) {
          this.maybeAssignSingleCell(cell, "south", row + 1, col);
        } else {
          this.maybeAssignSingleCell(cell, "north", row - 1, col);
        }
      } else {
        if (col % 2 === 0) {
          this.maybeAssignSingleCell(cell, "north", row - 1, col);
        } else {
          this.maybeAssignSingleCell(cell, "south", row + 1, col);
        }
      }
    }
  }
}
