import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import { BaseGrid } from "../../grids/BaseGrid";
import { CellRef } from "../../grids/Cell";
import { Distances } from "../../grids/Dijkstra";
import implementations from "../../MazeKinds";
import {
  computeDistances,
  endCellSelector,
  gridSelector,
  pathFindingSelector,
  startCellSelector,
} from "../../state/mazeFunctions";
import { selectCell } from "../../state/mazeSlice";
import { RootState } from "../../state/store";
import { MazeRenderer, MazeViewerProps } from "./MazeViewerProps";
import pinSvg from "./pin.svg";

export const Pin = ({
  grid,
  cellRef,
  renderer,
}: {
  grid: BaseGrid<unknown, unknown>;
  cellRef: CellRef | undefined;
  renderer: MazeRenderer;
}) => {
  if (cellRef === undefined) {
    return null;
  }

  const cell = grid.getCellFromRef(cellRef);
  const left = renderer.findCellLeft(cell);
  const top = renderer.findCellTop(cell);

  return (
    <PersonPinCircleIcon
      style={{
        height: renderer.cellSize(),
        width: renderer.cellSize(),
        position: "absolute",
        zIndex: 5,
        left,
        top,
      }}
    />
  );
};

function RenderMaze({
  grid,
  texture,
  distances,
  pathFinding,
  startCell,
  endCell,
  selectCell,
}: {
  grid: BaseGrid<unknown, unknown>;
  texture: boolean;
  distances?: Distances;
  pathFinding: boolean;
  startCell?: CellRef;
  endCell?: CellRef;
  selectCell: (cell: CellRef) => void;
}) {
  const canvas = useRef<HTMLCanvasElement>(null);
  const canvasHighlight = useRef<HTMLCanvasElement>(null);

  const selectLayer = useRef<HTMLCanvasElement>(null);

  const renderer = React.useMemo(() => {
    const viewer = implementations[grid.gridType].viewer;
    return new viewer(grid);
  }, [grid]);

  useEffect(() => {
    console.log("redrawing texture");
    const context = canvasHighlight?.current?.getContext("2d");
    if (context) {
      renderer!.renderTexture(context, distances);
    }
  }, [renderer, distances]);

  useEffect(() => {
    console.log("redrawing walls");
    const context = canvas?.current?.getContext("2d");
    if (context) {
      renderer!.renderGrid(context);
    }
  }, [renderer]);

  const height = renderer!.height();
  const width = renderer!.width();

  let cursor: string;
  if (pathFinding) {
    cursor = `url(${pinSvg}),pointer`;
  } else {
    cursor = "auto";
  }
  return (
    <>
      <canvas
        style={{
          position: "absolute",
          zIndex: 2,
          cursor,
        }}
        ref={selectLayer}
        data-testid="maze-canvas-select-layer"
        height={height}
        width={width}
        onClick={(e) => {
          pathFinding && renderer && renderer.onClick(e, selectCell);
        }}
      ></canvas>
      <Pin grid={grid} cellRef={startCell} renderer={renderer!} />
      <Pin grid={grid} cellRef={endCell} renderer={renderer!} />

      <canvas
        style={{
          position: "absolute",
          zIndex: 1,
        }}
        ref={canvas}
        data-testid="maze-canvas"
        height={height}
        width={width}
      ></canvas>
      <canvas
        style={{
          position: "absolute",
          zIndex: 0,
        }}
        ref={canvasHighlight}
        data-testid="maze-canvas-highlight"
        height={height}
        width={width}
      ></canvas>
    </>
  );
}

export function CanvasMaze(props: MazeViewerProps): JSX.Element | null {
  if (props.grid) {
    const grid: BaseGrid<unknown, unknown> = props.grid;
    return (
      <div
        data-testid="maze"
        style={{
          flex: "1 1 auto",
          padding: 20,
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <div
              style={{
                height,
                width,
                overflow: "scroll",
                position: "relative",
              }}
            >
              <RenderMaze
                grid={grid}
                texture={props.texture}
                distances={props.distances}
                pathFinding={props.pathFinding}
                startCell={props.startCell}
                endCell={props.endCell}
                selectCell={props.selectCell}
              ></RenderMaze>
            </div>
          )}
        </AutoSizer>
      </div>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    pathFinding: pathFindingSelector(state),
    grid: gridSelector(state),
    distances: computeDistances(state),
    startCell: startCellSelector(state),
    endCell: endCellSelector(state),
    texture: true,
  };
};
const mapDispatchToProps = {
  selectCell,
};

export default connect(mapStateToProps, mapDispatchToProps)(CanvasMaze);
