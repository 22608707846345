import { Action, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import rootReducer from "./rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

const persistConfig = {
  key: "root",
  storage: storageSession,
};

const createStore = () => {
  const store = configureStore({
    reducer: persistReducer<RootState>(persistConfig, rootReducer),
    middleware: getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  });

  const persistor = persistStore(store);

  return { store, persistor };
};
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default createStore;
