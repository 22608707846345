import { Grid, Cell } from "./Grid";
import { link } from "./Cell";

type ColumnPosition = { start: number; end: number };

export function findColumnPositions(line: string): ColumnPosition[] {
  const columns = [] as ColumnPosition[];

  if (!line.match(/^\+((-+)\+)+$/)) {
    throw new Error("Invalid characters in " + line);
  }

  let searchIndex = 0;
  while (searchIndex < line.length - 1) {
    const start = line.indexOf("+", searchIndex);
    const end = line.indexOf("+", start + 1);
    columns.push({ start: start, end: end + 1 });
    searchIndex = end;
  }

  return columns;
}

function transpose(lines: string[], index: number): string {
  return lines
    .map((line) => (line[index] === "|" ? "-" : line[index]))
    .join("");
}

function readCell(grid: Grid, cell: Cell, lines: string[]) {
  const validator = /^\+((-+)|( +))\+$/;
  const bottomSide = lines[lines.length - 1];
  const rightSide = transpose(lines, lines[0].length - 1);

  if (!bottomSide.match(validator)) {
    throw new Error(
      "Cell " + cell.row + " " + cell.column + " invalid content detected"
    );
  }
  if (!rightSide.match(validator)) {
    throw new Error(
      "Cell " + cell.row + " " + cell.column + " invalid content detected"
    );
  }
  if (
    lines[1][lines[1].length - 1] === " " &&
    cell.adjacentRefs.east !== undefined
  ) {
    link(cell, grid.getCellFromRef(cell.adjacentRefs.east));
  }
  if (
    lines[lines.length - 1][1] === " " &&
    cell.adjacentRefs.south !== undefined
  ) {
    link(cell, grid.getCellFromRef(cell.adjacentRefs.south));
  }

  cell.metadata = lines
    .slice(1, lines.length - 1)
    .map((x) => x.substring(2, lines[0].length - 2).trim())
    .join("\n")
    .trim();
}

/*
+---+---+---+---+---+
| 0   1   2   3   4 |
+   +   +---+   +   +
| 1 | 2 | 5   4 | 5 |
+---+---+---+---+   +
| a   9   8   7   6 |
+   +---+---+   +   +
| b | a   9   8 | 7 |
+---+   +   +   +   +
| c   b | a | 9 | 8 |
+---+---+---+---+---+
Excerpt From: Jamis Buck. “Mazes for Programmers (for Ben binford).” Apple Books. 
*/
export default function readGrid(externalForm: string): Grid {
  const lines = externalForm.split("\n");
  const cols = findColumnPositions(lines[0]);
  const rows = findColumnPositions(transpose(lines, 0));
  const grid = new Grid(rows.length, cols.length);

  for (let j = 0; j < rows.length; j++) {
    for (let i = 0; i < cols.length; i++) {
      readCell(
        grid,
        grid.getCell(j, i),
        lines
          .slice(rows[j].start, rows[j].end)
          .map((x) => x.substring(cols[i].start, cols[i].end))
      );
    }
  }

  return grid;
}
