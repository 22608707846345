import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { MazeKinds } from "../../MazeKinds";
import { GeneratorType } from "../../grids/GeneratorType";
import { algorithms } from "../../state/mazeAlgorithms";

export type CreateDialogState = {
  mazeType: GeneratorType;
  mazeKind: MazeKinds;
  gridRows?: number;
  gridColumns?: number;
  masking: boolean;
  newDialogOpen: boolean;
  gridRowsError?: string;
  gridColumnsError?: string;
  braid?: boolean;
  braidPercent?: number;
  braidPercentError?: string;
};

export const validAlgorithms = createSelector(
  [(s: CreateDialogState) => s],
  ({ mazeKind, masking }) => {
    return algorithms.filter(
      (e) => e.kinds.indexOf(mazeKind) >= 0 && (!masking || e.masking)
    );
  }
);

const createDialogSlice = createSlice({
  name: "createDialog",
  initialState: {
    mazeType: GeneratorType.RECURSIVE_BACKTRACKER,
    mazeKind: MazeKinds.RECTANGULAR,
    gridRows: 24,
    gridColumns: 24,
    masking: false,
    newDialogOpen: false,
  } as CreateDialogState,
  reducers: {
    setGridColumns(state, action: PayloadAction<number | undefined>) {
      if (!action.payload || action.payload < 2 || action.payload > 100) {
        state.gridColumnsError = "Columns must be between 2 and 100";
      } else {
        state.gridColumnsError = undefined;
      }
      state.gridColumns = action.payload;
    },
    setGridRows(state, action: PayloadAction<number | undefined>) {
      if (!action.payload || action.payload < 2 || action.payload > 100) {
        state.gridRowsError = "Rows must be between 2 and 100";
      } else {
        state.gridRowsError = undefined;
      }
      state.gridRows = action.payload;
    },
    setMazeType(state, action: PayloadAction<GeneratorType>) {
      state.mazeType = action.payload;
    },
    setMazeKind(state, action: PayloadAction<MazeKinds>) {
      state.mazeKind = action.payload;
      const alg = validAlgorithms(state);
      if (!alg.find((e) => e.value === state.mazeType)) {
        state.mazeType = alg[0].value;
      }
    },
    setMasking(state, action: PayloadAction<boolean>) {
      state.masking = action.payload;
      const alg = validAlgorithms(state);
      if (!alg.find((e) => e.value === state.mazeType)) {
        state.mazeType = alg[0].value;
      }
    },
    setNewDialogOpen(state, action: PayloadAction<boolean>) {
      state.newDialogOpen = action.payload;
    },
    setBraid(state, action: PayloadAction<boolean>) {
      state.braid = action.payload;
    },
    setBraidPercent(state, action: PayloadAction<number | undefined>) {
      if (!action.payload || action.payload < 0 || action.payload > 100) {
        state.braidPercentError =
          "Percentage of dead-ends to braid must be between 0 and 100";
      } else {
        state.braidPercentError = undefined;
      }
      state.braidPercent = action.payload;
    },
  },
});

export const {
  setGridColumns,
  setGridRows,
  setMazeType,
  setMazeKind,
  setMasking,
  setNewDialogOpen,
  setBraidPercent,
  setBraid,
} = createDialogSlice.actions;

export default createDialogSlice.reducer;
