import { eachCell, RowColumnGrid } from "./BaseGrid";
import { BaseCell, CellRef } from "./Cell";
import { MazeKinds } from "../MazeKinds";

export type HexDirections = {
  north?: CellRef;
  south?: CellRef;
  northEast?: CellRef;
  southEast?: CellRef;
  northWest?: CellRef;
  southWest?: CellRef;
};

export type HexCell = BaseCell<HexDirections>;

export class HexGrid extends RowColumnGrid<HexDirections> {
  constructor(rows: number, columns: number, cells?: HexCell[]) {
    super(MazeKinds.HEX, { rows, columns }, cells);
    this.initialize();
  }

  configureCells() {
    for (let cell of eachCell(this)) {
      let [row, col] = [cell.row, cell.column];
      // if column is even, row staggers up, otherwise staggers down
      const [northDiagonal, southDiagonal] =
        col % 2 === 0 ? [row - 1, row] : [row, row + 1];

      this.maybeAssignSingleCell(cell, "northWest", northDiagonal, col - 1);
      this.maybeAssignSingleCell(cell, "northEast", northDiagonal, col + 1);
      this.maybeAssignSingleCell(cell, "southWest", southDiagonal, col - 1);
      this.maybeAssignSingleCell(cell, "southEast", southDiagonal, col + 1);
      this.maybeAssignSingleCell(cell, "south", row + 1, col);
      this.maybeAssignSingleCell(cell, "north", row - 1, col);
    }
  }
}
