import { IconButton } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import React from "react";
import { connect } from "react-redux";
import { maskingSelector } from "../state/mazeFunctions";
import { completeMask } from "../state/mazeSlice";
import { RootState } from "../state/store";

export const CompleteMask = React.memo(
  ({
    shown,
    completeMask,
  }: {
    shown: boolean;
    completeMask: () => void;
  }): JSX.Element | null => {
    return shown ? (
      <IconButton
        color="inherit"
        aria-label="Complete Masking"
        title="Complete Masking"
        component="span"
        onClick={completeMask}
      >
        <DoneIcon />
      </IconButton>
    ) : null;
  }
);

const mapStateToProps = (state: RootState) => {
  return {
    shown: maskingSelector(state),
  };
};
const mapDispatchToProps = {
  completeMask,
};

const ConnectedCompleteMask = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompleteMask);

export default ConnectedCompleteMask;
