import React from "react";
import AppShell from "./ui/AppShell";
import CreateMaze from "./features/create/CreateMaze";
import EditLocation from "./ui/EditLocation";
import ExtraSettings from "./ui/ExtraSettings";
import OpenMaze from "./ui/OpenMaze";
import SaveButton from "./ui/SaveMaze";
import CanvasMaze from "./ui/viewers/CanvasMaze";
import CompleteMask from "./ui/CompleteMask";

function App() {
  return (
    <AppShell
      title="Mazes"
      tools={
        <>
          <CreateMaze />
          <CompleteMask />
          <OpenMaze />
          <SaveButton />
          <EditLocation />
        </>
      }
      contents={<CanvasMaze />}
      drawerContents={<ExtraSettings />}
    />
  );
}

export default React.memo(App);
