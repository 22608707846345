import { aldousBroder } from "../grids/AldousBroder";
import { BaseGrid } from "../grids/BaseGrid";
import { binaryTree } from "../grids/BinaryTree";
import { huntAndKill } from "../grids/HuntAndKill";
import { MazeKinds } from "../MazeKinds";
import { recursiveBacktracker } from "../grids/RecursiveBacktracker";
import { sidewinder } from "../grids/Sidewinder";
import { wilsons } from "../grids/Wilsons";
import { GeneratorType } from "../grids/GeneratorType";

export type algorithmType = {
  value: GeneratorType;
  display: string;
  kinds: MazeKinds[];
  masking: boolean;
  implementation: (g: BaseGrid<unknown, unknown>) => void;
};

export const algorithms: algorithmType[] = [
  {
    value: GeneratorType.BINARY_TREE,
    display: "Binary Tree",
    implementation: binaryTree,
    kinds: [MazeKinds.RECTANGULAR],
    masking: false,
  },
  {
    value: GeneratorType.SIDEWINDER,
    display: "Sidewinder",
    implementation: sidewinder,
    kinds: [MazeKinds.RECTANGULAR],
    masking: false,
  },
  {
    value: GeneratorType.ALDOUS_BRODER,
    display: "Aldous-Broder",
    implementation: aldousBroder,
    masking: true,
    kinds: [
      MazeKinds.RECTANGULAR,
      MazeKinds.POLAR,
      MazeKinds.HEX,
      MazeKinds.TRIANGULAR,
    ],
  },
  {
    value: GeneratorType.WILSON,
    display: "Wilson",
    implementation: wilsons,
    kinds: [
      MazeKinds.RECTANGULAR,
      MazeKinds.POLAR,
      MazeKinds.HEX,
      MazeKinds.TRIANGULAR,
    ],
    masking: true,
  },
  {
    value: GeneratorType.HUNT_AND_KILL,
    display: "Hunt and Kill",
    implementation: huntAndKill,
    kinds: [
      MazeKinds.RECTANGULAR,
      MazeKinds.POLAR,
      MazeKinds.HEX,
      MazeKinds.TRIANGULAR,
    ],
    masking: true,
  },
  {
    value: GeneratorType.RECURSIVE_BACKTRACKER,
    display: "Recursive Backtracker",
    implementation: recursiveBacktracker,
    kinds: [
      MazeKinds.RECTANGULAR,
      MazeKinds.POLAR,
      MazeKinds.HEX,
      MazeKinds.TRIANGULAR,
    ],
    masking: true,
  },
  {
    value: GeneratorType.NONE,
    display: "None",
    implementation: (_grid: BaseGrid<unknown, unknown>) => {},
    kinds: [
      MazeKinds.RECTANGULAR,
      MazeKinds.POLAR,
      MazeKinds.HEX,
      MazeKinds.TRIANGULAR,
    ],
    masking: true,
  },
];

export const generate = (
  kind: GeneratorType,
  grid: BaseGrid<unknown, unknown>,
  braid?: boolean,
  braidPercent?: number
) => {
  for (let a of algorithms) {
    if (a.value === kind) {
      a.implementation(grid);
      if (braid && braidPercent !== undefined) {
        grid.braid(braidPercent);
      }
      return;
    }
  }
};
