import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useCallback } from "react";
import { Highlight } from "../state/mazeTypes";
import { highlightSelector } from "../state/mazeFunctions";
import selectOptions from "../state/mazeHighlightOptions";
import { RootState } from "../state/store";
import { connect } from "react-redux";
import highlight from "../state/highlight";

//
export const ExtraSettings = React.memo(
  ({
    highlightType,
    onHighlightChange,
    highlightOptions,
  }: {
    highlightType: Highlight;
    onHighlightChange: (h: Highlight) => void;
    highlightOptions: { value: Highlight; label: string; disabled: boolean }[];
  }) => {
    const callback = useCallback((e) => onHighlightChange(e.target.value), [
      onHighlightChange,
    ]);

    const focusedOptions = React.useMemo(() => {
      const focusedOptions: {
        value: string;
        label: string;
        disabled: boolean;
        autoFocus?: boolean;
      }[] = [...highlightOptions];

      for (let i = 0; i < focusedOptions.length; i++) {
        if (!focusedOptions[i].disabled) {
          focusedOptions[i] = {
            ...focusedOptions[i],
            autoFocus: highlightType === focusedOptions[i].value,
          };
        }
      }
      return focusedOptions;
    }, [highlightOptions, highlightType]);

    return (
      <>
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Highlight</FormLabel>
          <RadioGroup
            aria-label="highlight"
            name="highlight"
            value={highlightType}
            onChange={callback}
          >
            {focusedOptions.map((o) => (
              <FormControlLabel
                key={`highlight-${o.value}`}
                id={`highlight-${o.value}`}
                value={o.value}
                control={<Radio autoFocus={o.autoFocus} />}
                label={o.label}
                disabled={o.disabled}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </>
    );
  }
);

const mapStateToProps = (state: RootState) => {
  return {
    highlightType: highlightSelector(state),
    highlightOptions: selectOptions(state),
  };
};
const mapDispatchToProps = {
  onHighlightChange: highlight,
};

const ConnectedExtraSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExtraSettings);

export default ConnectedExtraSettings;
