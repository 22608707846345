import { createSelector } from "reselect";
import { BaseGrid } from "../grids/BaseGrid";
import { CellRef } from "../grids/Cell";
import {
  Distances,
  findDistances,
  findLongestPath,
  findShortestPath,
} from "../grids/Dijkstra";
import implementations from "../MazeKinds";
import { Highlight, PathFindingStateType } from "./mazeTypes";
import { RootState } from "./store";

export const rawGridSelector = (state: RootState) => state.maze.grid;
export const highlightSelector = (state: RootState) => state.maze.highlight;
export const pathFindingStateSelector = (state: RootState) =>
  state.maze.pathFinding;
export const pathFindingSelector = (state: RootState) =>
  state.maze.pathFinding === PathFindingStateType.SelectingStart ||
  state.maze.pathFinding === PathFindingStateType.SelectingEnd ||
  state.maze.pathFinding === PathFindingStateType.SelectingMask;

export const startCellSelector = (state: RootState) => state.maze.startCell;
export const endCellSelector = (state: RootState) => state.maze.endCell;

export const maskingSelector = (state: RootState) =>
  state.maze.pathFinding === PathFindingStateType.SelectingMask;

export const gridSelector = createSelector([rawGridSelector], (grid) => {
  if (grid) {
    return implementations[grid.gridType].fromState(grid);
  } else {
    return undefined;
  }
});

export const validHighlightSelector = createSelector(
  [rawGridSelector, pathFindingStateSelector],
  (grid, pathFinding) => {
    if (grid) {
      if (pathFinding === PathFindingStateType.PathCompleted) {
        return [
          Highlight.SELECTED_PATH,
          Highlight.LONGEST_PATH,
          Highlight.NONE,
          Highlight.TEXTURE,
        ];
      } else if (pathFinding === PathFindingStateType.SelectingMask) {
        return [Highlight.NONE];
      } else {
        return [Highlight.LONGEST_PATH, Highlight.NONE, Highlight.TEXTURE];
      }
    } else {
      return [Highlight.NONE];
    }
  }
);

export const computeDistances = createSelector(
  [
    highlightSelector,
    pathFindingStateSelector,
    gridSelector,
    startCellSelector,
    endCellSelector,
  ],

  (
    highlight: Highlight,
    pathFinding: PathFindingStateType,
    grid?: BaseGrid<unknown, unknown>,
    startCell?: CellRef,
    endCell?: CellRef
  ): Distances => {
    if (highlight === Highlight.LONGEST_PATH && grid) {
      return findLongestPath(grid);
    } else if (highlight === Highlight.TEXTURE && grid) {
      return findDistances(grid, grid.getCenterCell());
    } else if (
      highlight === Highlight.SELECTED_PATH &&
      startCell &&
      endCell &&
      grid
    ) {
      return findShortestPath(
        grid,
        grid.getCellFromRef(startCell),
        grid.getCellFromRef(endCell)
      );
    } else {
      return new Map();
    }
  }
);
