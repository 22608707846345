import { BaseGrid } from "./BaseGrid";
import { CellRef, BaseCell, linkedNeighbors } from "./Cell";

export type Distances = Map<CellRef, number>;

export function findDistances<T, M>(
  grid: BaseGrid<T, M>,
  root: BaseCell<T>,
  neighborFunction = linkedNeighbors
): Distances {
  const distances: Distances = new Map();
  distances.set(root.coords, 0);

  let step = 1;
  let priorCells = new Set([root.coords]);
  let currentCells = new Set(neighborFunction(root));

  while (currentCells.size > 0) {
    const nextCells = new Set<CellRef>();
    for (let cell of currentCells) {
      distances.set(cell, step);
      for (let neighbor of neighborFunction(grid.getCellFromRef(cell))) {
        if (!priorCells.has(neighbor)) {
          nextCells.add(neighbor);
        }
      }
    }
    step++;
    priorCells = currentCells;
    currentCells = nextCells;
  }

  return distances;
}

function findShortestPathFromDistances<T, M>(
  grid: BaseGrid<T, M>,
  distances: Distances,
  initial: BaseCell<T>,
  goal: BaseCell<T>
): Distances {
  const breadcrumbs: Distances = new Map();

  let current = goal;

  while (true) {
    const currentStep = distances.get(current.coords)!;
    breadcrumbs.set(current.coords, currentStep);
    if (current === initial) {
      break;
    }
    for (let linkedCell of current.links) {
      const linkedStep = distances.get(linkedCell)!;
      if (linkedStep < currentStep) {
        current = grid.getCellFromRef(linkedCell);
        break;
      }
    }
  }

  return breadcrumbs;
}

export function findShortestPath<T, M>(
  grid: BaseGrid<T, M>,
  initial: BaseCell<T>,
  goal: BaseCell<T>
): Distances {
  const distances = findDistances(grid, initial);
  const breadcrumbs: Distances = new Map();

  let current = goal;

  while (true) {
    const currentStep = distances.get(current.coords)!;
    breadcrumbs.set(current.coords, currentStep);
    if (current === initial) {
      break;
    }
    for (let linkedCell of current.links) {
      const linkedStep = distances.get(linkedCell)!;
      if (linkedStep < currentStep) {
        current = grid.getCellFromRef(linkedCell);
        break;
      }
    }
  }

  return breadcrumbs;
}

function findMaxDistance<T, M>(
  grid: BaseGrid<T, M>,
  distances: Distances
): BaseCell<T> {
  let max = 0,
    targetCell = undefined;
  for (let [c, d] of distances.entries()) {
    if (d > max) {
      max = d;
      targetCell = c;
    }
  }
  return grid.getCellFromRef(targetCell!);
}

export function findLongestPath<T, M>(grid: BaseGrid<T, M>): Distances {
  const distances = findDistances(grid, grid.getNearestCellFromRef(1));

  const startCell = findMaxDistance(grid, distances);

  const startDistances = findDistances(grid, startCell);

  const endCell = findMaxDistance(grid, startDistances);

  return findShortestPathFromDistances(
    grid,
    startDistances,
    startCell,
    endCell
  );
}
