import { BaseGrid } from "./BaseGrid";
import { neighbors, link, BaseCell } from "./Cell";
import { getRandomInt } from "./utils";

export function recursiveBacktracker<T, M>(grid: BaseGrid<T, M>) {
  const path: BaseCell<T>[] = [];
  path.push(grid.randomCell());
  while (path.length > 0) {
    const cell = path[path.length - 1];
    const adj = neighbors(cell)
      .map((x) => grid.getCellFromRef(x))
      .filter((x) => x.links.length === 0);
    if (adj.length === 0) {
      path.pop();
    } else {
      const neighbor = adj[getRandomInt(adj.length)];
      link(cell, neighbor);
      path.push(neighbor);
    }
  }
}
