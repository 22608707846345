import { BaseCell } from "../../grids/Cell";
import {
  TriangularCell,
  TriangularDirections,
  TriangularGrid,
} from "../../grids/TriangularGrid";
import MazeEngine, { Walls } from "./MazeEngine";
import { round } from "./utils";

const cellSize = 25;

const cellHeight = round(Math.sqrt(25 * 25 - 12.5 * 12.5), 4);

export function findCellLeft(cell: BaseCell<unknown>) {
  return (cell.column * cellSize) / 2;
}

export function findCellTop(cell: BaseCell<unknown>) {
  return cell.row * cellHeight;
}

class TriangularMaze extends MazeEngine<TriangularGrid, TriangularDirections> {
  cellSize() {
    return 20;
  }
  findCellLeft(cell: BaseCell<unknown>) {
    return findCellLeft(cell);
  }

  findCellTop(cell: BaseCell<unknown>) {
    return findCellTop(cell);
  }

  height() {
    return Math.ceil(cellHeight * this.grid.description.rows);
  }
  width() {
    return Math.ceil((cellSize * (this.grid.description.columns + 1)) / 2);
  }

  calculateWalls(cell: TriangularCell): Walls<TriangularDirections> {
    const left = findCellLeft(cell);
    const top = findCellTop(cell);
    const right = left + cellSize - 1;
    const midPoint = (left + right) / 2;
    const bottom = top + cellHeight - 1;
    const evenColumn = cell.column % 2 === 0;
    const evenRow = cell.row % 2 === 0;

    if ((evenColumn && !evenRow) || (!evenColumn && evenRow)) {
      // down pointing triangle
      return {
        cell: cell,
        walls: [
          {
            kind: "line",
            neighbor: cell.adjacentRefs.west,
            x1: midPoint,
            y1: bottom,
            x2: left,
            y2: top,
          },
          {
            kind: "line",
            neighbor: cell.adjacentRefs.north,
            x1: left,
            y1: top,
            x2: right,
            y2: top,
          },
          {
            kind: "line",
            neighbor: cell.adjacentRefs.east,
            x1: right,
            y1: top,
            x2: midPoint,
            y2: bottom,
          },
        ],
      };
    } else {
      return {
        cell: cell,
        walls: [
          {
            kind: "line",
            neighbor: cell.adjacentRefs.west,
            x1: left,
            y1: bottom,
            x2: midPoint,
            y2: top,
          },
          {
            kind: "line",
            neighbor: cell.adjacentRefs.east,
            x1: midPoint,
            y1: top,
            x2: right,
            y2: bottom,
          },
          {
            kind: "line",
            neighbor: cell.adjacentRefs.south,
            x1: right,
            y1: bottom,
            x2: left,
            y2: bottom,
          },
        ],
      };
    }
  }
}

export default TriangularMaze;
