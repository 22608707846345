import { getRandomInt } from "./utils";

export type CellRef = number;

export type BaseCell<T> = {
  readonly row: number;
  readonly column: number;
  readonly coords: CellRef;
  metadata?: string;
  adjacentRefs: T;
  masked?: boolean;
  maskedNeighbors?: CellRef[];
  readonly links: CellRef[];
};

export const createCell = <T>(
  row: number,
  column: number,
  coords: number
): BaseCell<T> => {
  return { coords, row, column, links: [], adjacentRefs: {} as T };
};

const findLinkIndex = <T>(cell: BaseCell<T>, cellRef?: CellRef): number => {
  if (cellRef === undefined) {
    return -1;
  }
  return cell.links.indexOf(cellRef);
};

export const isLinked = <T>(cell: BaseCell<T>, cellRef?: CellRef): boolean => {
  return findLinkIndex(cell, cellRef) >= 0;
};

export const link = <T>(
  a: BaseCell<T>,
  b: BaseCell<T>,
  bidi = true
): BaseCell<T> => {
  if (a.links.includes(b.coords)) {
    throw new Error("Cannot double link a cell ");
  }
  a.links.push(b.coords);
  if (bidi) {
    link(b, a, false);
  }
  return a;
};

export const unlink = <T>(
  a: BaseCell<T>,
  b: BaseCell<T>,
  bidi = true
): BaseCell<T> => {
  const deleteIdx = findLinkIndex(a, b.coords);
  if (deleteIdx >= 0) {
    a.links.splice(deleteIdx, 1);
  }
  if (bidi) {
    unlink(b, a, false);
  }
  return a;
};

export const neighbors = <T>(
  cell: BaseCell<T>,
  includeMasked?: boolean
): CellRef[] => {
  const neighbors = [];
  const directions = Object.getOwnPropertyNames(cell.adjacentRefs);
  directions.sort();

  for (let dir of directions) {
    const adj = (cell.adjacentRefs as any)[dir];
    if (adj) {
      if (Array.isArray(adj)) {
        for (let x of adj) {
          (includeMasked ||
            !cell.maskedNeighbors ||
            !cell.maskedNeighbors.includes(x)) &&
            neighbors.push(x);
        }
      } else {
        (includeMasked ||
          !cell.maskedNeighbors ||
          !cell.maskedNeighbors.includes(adj)) &&
          neighbors.push(adj);
      }
    }
  }

  return neighbors;
};

export const maskRef = <T>(cell: BaseCell<T>, ref: CellRef) => {
  if (!cell.maskedNeighbors) {
    cell.maskedNeighbors = [];
  }
  cell.maskedNeighbors.push(ref);
};

export const unmaskRef = <T>(cell: BaseCell<T>, ref: CellRef) => {
  if (!cell.maskedNeighbors) {
    return;
  }
  const idx = cell.maskedNeighbors.indexOf(ref);
  if (idx >= 0) {
    if (cell.maskedNeighbors.length === 1) {
      delete cell.maskedNeighbors;
    } else {
      cell.maskedNeighbors.splice(idx, 1);
    }
  }
};

export const isDeadEnd = <T>(cell: BaseCell<T>): boolean => {
  return cell.links.length === 1;
};

export const masked = <T>(cell: BaseCell<T>): boolean => {
  return !!cell.masked;
};

export const linkedNeighbors = <T>(cell?: BaseCell<T>): CellRef[] => {
  if (!cell) {
    return [];
  }
  return neighbors(cell).filter((x) => isLinked(cell, x));
};

export const randomNeighbor = <T>(cell: BaseCell<T>): CellRef => {
  return neighbors(cell)[getRandomInt(neighbors(cell).length)];
};

export const cellEquals = <T>(a?: BaseCell<T>, b?: BaseCell<T>) => {
  if (a?.row !== b?.row || a?.column !== b?.column) {
    return false;
  }

  const n1 = linkedNeighbors(a);
  const n2 = linkedNeighbors(b);

  if (n1.length !== n2.length) {
    return false;
  }

  for (let i = 0; i < n1.length; i++) {
    if (n1[i] !== n2[i]) {
      return false;
    }
  }

  return true;
};
