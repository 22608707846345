import { IconButton } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import FileSaver from "file-saver";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { default as React, useCallback } from "react";
import { connect } from "react-redux";
import { BaseGrid } from "../grids/BaseGrid";
import { gridSelector, computeDistances } from "../state/mazeFunctions";
import { startPathfinding } from "../state/mazeSlice";
import { RootState } from "../state/store";
import implementations from "../MazeKinds";
import { Distances } from "../grids/Dijkstra";

function saveGrid(grid: BaseGrid<unknown, unknown>) {
  var blob = new Blob([grid.writeGrid()], {
    type: "text/plain;charset=utf-8",
  });
  FileSaver.saveAs(blob, "Untitled Maze.maze");
}

function savePng(grid: BaseGrid<unknown, unknown>, distances?: Distances) {
  const viewer = new implementations[grid.gridType].viewer(grid);

  const canvas: HTMLCanvasElement = document.createElement("canvas");
  canvas.height = viewer.height();
  canvas.width = viewer.width();

  const ctx = canvas.getContext("2d");

  viewer.render(ctx!, distances);
  canvas.toBlob((blob) => {
    if (blob) FileSaver.saveAs(blob, "Untitled Maze.png");
  });
}

export const SaveMaze = ({
  grid,
  distances,
}: {
  grid?: BaseGrid<unknown, unknown>;
  distances?: Distances;
}) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "saveMenu",
  });

  return (
    <React.Fragment>
      <IconButton
        color="inherit"
        aria-label="Save Maze"
        title="Save Maze"
        component="span"
        disabled={!grid}
        id="saveMaze"
        {...bindTrigger(popupState)}
      >
        <SaveAltIcon />
      </IconButton>
      <Menu {...bindMenu(popupState)}>
        <MenuItem
          onClick={() => {
            popupState.close();
            saveGrid(grid!);
          }}
        >
          Save as maze
        </MenuItem>
        <MenuItem
          onClick={() => {
            popupState.close();
            savePng(grid!, distances);
          }}
        >
          Save as image
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    grid: gridSelector(state),
    distances: computeDistances(state),
  };
};
const mapDispatchToProps = {
  startPathRecording: startPathfinding,
};

const ConnectedSaveMaze = connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SaveMaze));

export default ConnectedSaveMaze;
