import { BaseCell, link, randomNeighbor } from "./Cell";
import { BaseGrid } from "./BaseGrid";

function popSet<T>(set: Set<BaseCell<T>>): BaseCell<T> {
  const cell = set.values().next().value;
  set.delete(cell);
  return cell;
}

export function wilsons<T, M>(grid: BaseGrid<T, M>) {
  let unvisited = new Set(grid.randomCells());

  popSet(unvisited);

  while (unvisited.size > 0) {
    let path: BaseCell<T>[] = [];

    let cell: BaseCell<T> = unvisited.values().next().value;
    while (true) {
      let intersect = path.indexOf(cell!);
      if (intersect >= 0) {
        path = path.slice(0, intersect + 1);
      } else {
        path.push(cell!);
      }

      if (!unvisited.has(cell)) {
        break;
      }
      cell = grid.getCellFromRef(randomNeighbor(cell!));
    }

    for (let i = 0; i < path.length - 1; i++) {
      unvisited.delete(path[i]);
      link(path[i], path[i + 1]);
    }
  }
}
