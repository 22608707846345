import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Color } from "@material-ui/lab/Alert/Alert";

export type ToastState = {
  display: boolean;
  severity?: Color;
  message?: string;
};

const createAlert = (severity: Color) => (
  state: ToastState,
  action: PayloadAction<string>
) => {
  state.severity = severity;
  state.display = true;
  state.message = action.payload;
};

const toastSlice = createSlice({
  name: "toast",
  initialState: {
    display: false,
  } as ToastState,
  reducers: {
    info: createAlert("info"),

    success: createAlert("success"),

    warning: createAlert("warning"),

    error: createAlert("error"),

    hide(state, action: PayloadAction<void>) {
      state.display = false;
    },
  },
});

export const { hide, error, warning, success, info } = toastSlice.actions;

export default toastSlice.reducer;
