import { createSelector } from "reselect";
import { validHighlightSelector } from "./mazeFunctions";
import { Highlight } from "./mazeTypes";

const mazeHighlightOptions = [
  {
    value: Highlight.SELECTED_PATH,
    label: "Selected Path",
  },
  { value: Highlight.LONGEST_PATH, label: "Longest Path" },
  { value: Highlight.TEXTURE, label: "Texture" },
  { value: Highlight.NONE, label: "None" },
];

const selectOptions = createSelector(
  [validHighlightSelector],
  (
    validHighlights
  ): {
    value: Highlight;
    label: string;
    disabled: boolean;
  }[] => {
    const myOptions = [];
    for (let option of mazeHighlightOptions) {
      myOptions.push({
        ...option,
        disabled: validHighlights.indexOf(option.value) < 0,
      });
    }
    return myOptions;
  }
);

export default selectOptions;
