export function getRandomInt(max: number): number {
  return Math.floor(Math.random() * Math.floor(max));
}

export function mod(n: number, m: number): number {
  return ((n % m) + m) % m;
}

export function between(needle: number, haystack1: number, haystack2: number) {
  return (
    (needle >= haystack1 && needle <= haystack2) ||
    (needle <= haystack1 && needle >= haystack2)
  );
}

export function shuffle<T>(array: T[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}
export type FilterFlags<Base, Condition> = {
  [Key in keyof Base]: Base[Key] extends Condition ? Key : never;
};

export type AllowedNames<Base, Condition> = FilterFlags<
  Base,
  Condition
>[keyof Base];
