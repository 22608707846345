import { eachCell, RowGrid } from "./BaseGrid";
import { BaseCell, CellRef } from "./Cell";
import { mod } from "./utils";
import { MazeKinds } from "../MazeKinds";

export type PolarDirections = {
  inward?: CellRef;
  cw?: CellRef;
  ccw?: CellRef;
  outward?: CellRef[];
};

export type PolarDescription = {
  readonly rows: number;
};

export type PolarCell = BaseCell<PolarDirections>;

export class PolarGrid extends RowGrid<PolarDirections, PolarDescription> {
  private readonly cellsByRow: CellRef[][];

  constructor(rows: number, cells?: PolarCell[]) {
    super(MazeKinds.POLAR, { rows }, cells);
    this.cellsByRow = [];
    this.initialize();
  }

  prepareGrid(): void {
    const rowHeight = 1.0 / this.description.rows;
    this.cellsByRow[0] = [this.newCell(0, 0).coords];

    for (let r = 1; r < this.description.rows; r++) {
      const radius = r / this.description.rows;
      const circumference = 2 * Math.PI * radius;

      const previousCount = this.cellsByRow[r - 1].length;
      const estimatedCellWidth = circumference / previousCount;
      const ratio = Math.round(estimatedCellWidth / rowHeight);
      const columns = previousCount * ratio;

      this.cellsByRow[r] = [];
      for (let c = 0; c < columns; c++) {
        this.cellsByRow[r].push(this.newCell(r, c).coords);
      }
    }
  }

  configureCells() {
    for (let cell of eachCell(this)) {
      if (cell.row > 0) {
        cell.adjacentRefs.cw = this.getCell(cell.row, cell.column + 1).coords;
        cell.adjacentRefs.ccw = this.getCell(cell.row, cell.column - 1).coords;

        const ratio =
          this.cellsByRow[cell.row].length /
          this.cellsByRow[cell.row - 1].length;
        const parent = this.getCellFromRef(
          this.cellsByRow[cell.row - 1][Math.floor(cell.column / ratio)]
        );
        if (!parent.adjacentRefs.outward) {
          parent.adjacentRefs.outward = [];
        }
        parent.adjacentRefs.outward.push(cell.coords);
        cell.adjacentRefs.inward = parent.coords;
      }
    }
  }

  getRow(row: number): PolarCell[] {
    return this.cellsByRow![row].map((ref) => this.getCellFromRef(ref));
  }
  getCell(row: number, column: number): PolarCell {
    const cellRow = this.cellsByRow![row];
    return this.getCellFromRef(cellRow[mod(column, cellRow.length)]);
  }

  getCenterCell() {
    return this.getCell(0, 0);
  }
}
