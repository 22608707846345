import { BaseCell } from "../../grids/Cell";
import { HexCell, HexDirections, HexGrid } from "../../grids/HexGrid";
import { MazeEngine, Walls } from "./MazeEngine";
import { round } from "./utils";

const sideLength = 20;

const aSize = sideLength / 2;

//const width = sideLength * 2;

const bSize = round((sideLength * Math.sqrt(3)) / 2, 4);

const height = bSize * 2;

type HexPoints = {
  x_fw: number;
  x_nw: number;
  x_ne: number;
  x_se: number;
  x_sw: number;
  x_fe: number;
  y_n: number;
  y_m: number;
  y_s: number;
};

class HexMaze extends MazeEngine<HexGrid, HexDirections> {
  cellSize() {
    return 3 * aSize;
  }

  findCellLeft(cell: BaseCell<unknown>): number {
    return 3 * cell.column * aSize + aSize / 2;
  }

  findCellTop(cell: BaseCell<unknown>): number {
    return (
      cell.row * height +
      (cell.column % 2 === 1 ? bSize : 0) +
      (2 * bSize - this.cellSize()) / 2
    );
  }

  height(): number {
    return Math.ceil(this.grid.description.rows * height + bSize);
  }
  width(): number {
    return Math.ceil(3 * this.grid.description.columns * aSize + aSize);
  }

  calculateWalls(cell: HexCell): Walls<HexDirections> {
    const cx = sideLength + 3 * cell.column * aSize;
    const cy = bSize + cell.row * height + (cell.column % 2 === 1 ? bSize : 0);
    const x_fw = round(cx - sideLength, 4);
    const x_nw = round(cx - aSize, 4);
    const x_ne = round(cx + aSize, 4);
    const x_fe = round(cx + sideLength, 4);
    const y_n = round(cy - bSize, 4);
    const y_m = round(cy, 4);
    const y_s = round(cy + bSize, 4);

    return {
      cell: cell,
      walls: [
        {
          kind: "line",
          neighbor: cell.adjacentRefs.southWest,
          x1: x_fw,
          y1: y_m,
          x2: x_nw,
          y2: y_s,
        },
        {
          kind: "line",
          neighbor: cell.adjacentRefs.south,
          x1: x_nw,
          y1: y_s,
          x2: x_ne,
          y2: y_s,
        },
        {
          kind: "line",
          neighbor: cell.adjacentRefs.south,
          x1: x_nw,
          y1: y_s,
          x2: x_ne,
          y2: y_s,
        },
        {
          kind: "line",
          neighbor: cell.adjacentRefs.southEast,
          x1: x_ne,
          y1: y_s,
          x2: x_fe,
          y2: y_m,
        },
        {
          kind: "line",
          neighbor: cell.adjacentRefs.northEast,
          x1: x_fe,
          y1: y_m,
          x2: x_ne,
          y2: y_n,
        },
        {
          kind: "line",
          neighbor: cell.adjacentRefs.north,
          x1: x_ne,
          y1: y_n,
          x2: x_nw,
          y2: y_n,
        },
        {
          kind: "line",
          neighbor: cell.adjacentRefs.northWest,
          x1: x_nw,
          y1: y_n,
          x2: x_fw,
          y2: y_m,
        },
      ],
    };
  }
}

export default HexMaze;
