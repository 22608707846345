import { read } from "./mazeSlice";
import { error } from "./toastSlice";
import { AppThunk } from "./store";

const readAndHandleErrors = (externalForm: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch(read(externalForm));
    dispatch((dispatch, getState) => {
      const readingError = getState().maze.gridReadingError;
      if (readingError) {
        dispatch(error(readingError));
      }
    });
  };
};

export default readAndHandleErrors;
