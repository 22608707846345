import { BaseGrid } from "./BaseGrid";
import { getRandomInt } from "./utils";
import { neighbors, link } from "./Cell";

export function aldousBroder<T, M>(grid: BaseGrid<T, M>) {
  let cell = grid.randomCell();
  let unvisited = grid.size() - 1 - grid.maskedCount();

  while (unvisited > 0) {
    const adj = neighbors(cell);
    const neighborRef = adj[getRandomInt(adj.length)];
    const neighbor = grid.getCellFromRef(neighborRef);
    if (neighbor.links.length === 0) {
      link(cell, neighbor);
      unvisited--;
    }
    cell = neighbor;
  }
}
