import { eachCell, RowColumnGrid } from "./BaseGrid";
import { BaseCell, CellRef } from "./Cell";
import writeGrid from "./GridWriter";
import { MazeKinds } from "../MazeKinds";

export type Cell = BaseCell<GridDirections>;

export type GridDirections = {
  north?: CellRef;
  south?: CellRef;
  east?: CellRef;
  west?: CellRef;
};

export class Grid extends RowColumnGrid<GridDirections> {
  constructor(rows: number, columns: number, cells?: Cell[]) {
    super(MazeKinds.RECTANGULAR, { rows, columns }, cells);
    this.initialize();
  }

  configureCells() {
    for (let cell of eachCell(this)) {
      this.maybeAssignSingleCell(cell, "north", cell.row - 1, cell.column);
      this.maybeAssignSingleCell(cell, "west", cell.row, cell.column - 1);
      this.maybeAssignSingleCell(cell, "east", cell.row, cell.column + 1);
      this.maybeAssignSingleCell(cell, "south", cell.row + 1, cell.column);
    }
  }

  writeGrid() {
    if (this.grid.find((e) => !!e.masked)) {
      return super.writeGrid();
    }
    return writeGrid(this);
  }
}
