import { CellRef } from "../grids/Cell";
import { CreateMazeType } from "../grids/CreateMazeType";
import { GridMazeState } from "../grids/GridMazeState";

export enum Highlight {
  LONGEST_PATH = "LongestPath",
  TEXTURE = "Texture",
  NONE = "None",
  SELECTED_PATH = "SelectedPath",
}

export enum PathFindingStateType {
  NotPathfinding = "NotPathFinding",
  SelectingStart = "SelectingStart",
  SelectingEnd = "SelectingEnd",
  PathCompleted = "PathCompleted",
  SelectingMask = "SelectingMask",
}

export enum RenderOptions {
  LONGEST_PATH = "LongestPath",
  TEXTURE = "Texture",
  NONE = "None",
  SELECTED_PATH = "SelectedPath",
}

export interface MazeState {
  highlight: Highlight;
  grid?: GridMazeState;
  gridReadingError?: string;
  pathFinding: PathFindingStateType;
  startCell?: CellRef;
  endCell?: CellRef;
  pendingCreate?: CreateMazeType;
}
