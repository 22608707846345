import { BaseGrid } from "./BaseGrid";
import readGrid from "./GridReader";
import implementations, { MazeKinds } from "../MazeKinds";

export const reader = (s: string): BaseGrid<unknown, unknown> => {
  const t = s.substring(0, s.indexOf("\n"));
  if (t.startsWith("+-")) {
    return readGrid(s);
  } else {
    const json = JSON.parse(s);
    if (json.gridType && typeof json.gridType === "string") {
      return implementations[json.gridType as MazeKinds].fromState(json);
    } else {
      throw new Error("Unknown external form");
    }
  }
};
