import { Grid, Cell } from "./Grid";
import { eachCell, BaseGrid } from "./BaseGrid";
import { getRandomInt } from "./utils";
import { link } from "./Cell";

export function binaryTree<T, M>(grid: BaseGrid<T, M>) {
  if (grid instanceof Grid) {
    for (let cell of eachCell(grid)) {
      const potentials: Cell[] = [];
      if (cell.adjacentRefs.north) {
        potentials.push(grid.getCellFromRef(cell.adjacentRefs.north));
      }
      if (cell.adjacentRefs.east) {
        potentials.push(grid.getCellFromRef(cell.adjacentRefs.east));
      }
      if (potentials.length > 0) {
        link(cell, potentials[getRandomInt(potentials.length)]);
      }
    }
  } else {
    throw new Error("Binary Tree is only supported for rectangular grids");
  }
}
