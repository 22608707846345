import { Distances } from "../../grids/Dijkstra";

export const colors = [
  "#a0e7a2",
  "#97df99",
  "#8ed690",
  "#85ce87",
  "#7cc67e",
  "#74be75",
  "#6bb66c",
  "#62ae64",
  "#59a65b",
  "#509e53",
  "#47974a",
  "#3e8f42",
  "#35873a",
  "#2a8032",
  "#1f7829",
  "#117121",
];
export function mapDistancesToIndexedColor(distances: Distances): number[] {
  let maxDistance = 0;
  for (let v of distances.values()) {
    if (v > maxDistance) {
      maxDistance = v;
    }
  }

  const spacing = 16.0 / (maxDistance + 1);

  const palette: number[] = [];
  for (let i = 0; i <= maxDistance; i++) {
    palette[i] = Math.floor(i * spacing);
  }
  return palette;
}

export function round(value: number, decimals: number) {
  // @ts-ignore:2345
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}
