import { Box } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import {
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import { PayloadActionCreator } from "@reduxjs/toolkit";
import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { ToastState } from "../state/toastSlice";
import { hide } from "../state/toastSlice";
import { Toaster } from "./Toaster";
import { RootState } from "../state/store";
import { createMuiTheme } from "@material-ui/core/styles";
const drawerWidth = 240;

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",
    },
    title: {
      flexGrow: 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    panelControls: {
      margin: theme.spacing(1),
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    content: {
      flexGrow: 1,

      display: "flex",
      flexDirection: "column",
    },
  })
);

type AppShellProps = {
  drawerContents: any;
  tools: any;
  contents: any;
  title: string;
  toast: ToastState;
  hide: PayloadActionCreator<void>;
};

export const AppShell = React.memo(
  ({ drawerContents, tools, contents, title, toast, hide }: AppShellProps) => {
    const [open, setOpen] = useState(false);

    const classes = useStyles();

    const toggleDrawer = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpen(!open);
      },
      [setOpen, open]
    );

    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root} data-testid="maze-ui">
          <CssBaseline />
          <Toaster hide={hide} toast={toast} />
          <AppBar>
            <Toolbar>
              <Typography variant="h6" noWrap className={classes.title}>
                {title}
              </Typography>

              {tools}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                id="openDrawer"
                onClick={toggleDrawer}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <main className={classes.content}>
            <div className={classes.drawerHeader} />
            {contents}
          </main>
          <Drawer
            className={classes.drawer}
            variant="temporary"
            anchor="right"
            open={open}
            onClose={toggleDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={toggleDrawer} aria-label="close drawer">
                <ChevronRightIcon />
              </IconButton>
            </div>
            <Divider />
            <Box className={classes.panelControls}>{drawerContents}</Box>
          </Drawer>
        </div>
      </ThemeProvider>
    );
  }
);

const mapStateToProps = (state: RootState) => {
  return {
    toast: state.toast,
  };
};
const mapDispatchToProps = {
  hide,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppShell);
