import { IconButton } from "@material-ui/core";
import EditLocationIcon from "@material-ui/icons/EditLocation";
import { PayloadActionCreator } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { gridSelector, maskingSelector } from "../state/mazeFunctions";
import { startPathfinding } from "../state/mazeSlice";
import { RootState } from "../state/store";

export const EditLocation = React.memo(
  ({
    disabled,
    startPathRecording,
  }: {
    disabled: boolean;
    startPathRecording: PayloadActionCreator<void>;
  }) => {
    return (
      <React.Fragment>
        <IconButton
          color="inherit"
          aria-label="Select a path"
          title="Select a path"
          component="span"
          onClick={startPathRecording}
          disabled={disabled}
          id="selectPath"
        >
          <EditLocationIcon />
        </IconButton>
      </React.Fragment>
    );
  }
);

const mapStateToProps = (state: RootState) => {
  return {
    disabled: !gridSelector(state) || maskingSelector(state),
  };
};
const mapDispatchToProps = {
  startPathRecording: startPathfinding,
};

const ConnectedEditLocation = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLocation);

export default ConnectedEditLocation;
