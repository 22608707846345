import { combineReducers } from "@reduxjs/toolkit";
import mazeSlice from "./mazeSlice";
import toastSlice from "./toastSlice";
import createDialog from "../features/create/createSlice";

const rootReducer = combineReducers({
  toast: toastSlice,
  maze: mazeSlice,
  createDialog: createDialog,
});
export default rootReducer;
