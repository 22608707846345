import { BaseCell, link, neighbors } from "./Cell";
import { BaseGrid, eachCell } from "./BaseGrid";
import { getRandomInt } from "./utils";

const findAndAdjoinUnvisitedCell = <T, M>(
  grid: BaseGrid<T, M>
): BaseCell<T> | undefined => {
  for (let cell of eachCell(grid, (x) => x.links.length === 0)) {
    const adj = neighbors(cell)
      .map((x) => grid.getCellFromRef(x))
      .filter((x) => x.links.length > 0);
    if (adj.length > 0) {
      const neighbor = adj[getRandomInt(adj.length)];
      link(cell, neighbor);
      return cell;
    }
  }
};

export function huntAndKill<T, M>(grid: BaseGrid<T, M>) {
  let cell = grid.randomCell();

  while (true) {
    const adj = neighbors(cell)
      .map((x) => grid.getCellFromRef(x))
      .filter((x) => x.links.length === 0);
    if (adj.length > 0) {
      const neighbor = adj[getRandomInt(adj.length)];
      link(cell, neighbor);
      cell = neighbor;
    } else {
      let potential = findAndAdjoinUnvisitedCell(grid);
      if (potential) {
        cell = potential;
      } else {
        break;
      }
    }
  }
}
