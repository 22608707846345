import { BaseGrid } from "../../grids/BaseGrid";
import { BaseCell, CellRef } from "../../grids/Cell";
import { PolarDirections, PolarGrid } from "../../grids/PolarGrid";
import MazeEngine, { Wall, Walls } from "./MazeEngine";

const cellSize = 20;

export function length(numCells: number): number {
  return numCells * cellSize;
}
type CellBound = {
  cellRef: CellRef;
  thetaCcw: number;
  thetaCw: number;
  innerRadius: number;
  outerRadius: number;
};
class PolarMaze extends MazeEngine<PolarGrid, PolarDirections> {
  radius: number;

  cellBounds: CellBound[] = [];

  constructor(grid: BaseGrid<unknown, unknown>) {
    super(grid as PolarGrid);
    this.radius = length(this.grid.description.rows);
    for (let r = this.grid.description.rows - 1; r > 0; r--) {
      const row = this.grid.getRow(r);
      const theta = (2 * Math.PI) / row.length;
      const innerRadius = length(r);
      const outerRadius = length(r + 1);
      for (let c = 0; c < row.length; c++) {
        const cell = this.grid.getCell(r, c);

        const thetaCcw = cell.column * theta;
        const thetaCw = (cell.column + 1) * theta;

        this.cellBounds[cell.coords] = {
          cellRef: cell.coords,
          innerRadius,
          outerRadius,
          thetaCcw,
          thetaCw,
        };
      }
    }

    this.cellBounds[1] = {
      cellRef: 1,
      innerRadius: 0,
      outerRadius: cellSize,
      thetaCw: 2 * Math.PI,
      thetaCcw: 0,
    };
  }

  findCellLeft(cell: BaseCell<unknown>): number {
    if (cell.row === 0) {
      return this.radius - this.cellSize() / 2;
    }
    const r = cell.row;
    const row = this.grid.getRow(r);
    const theta = (2 * Math.PI) / row.length;

    const centerRadius = (length(r) + length(r + 1)) / 2;
    const centerTheta = (cell.column * theta + (cell.column + 1) * theta) / 2;
    return (
      this.radius + centerRadius * Math.cos(centerTheta) - this.cellSize() / 2
    );
  }

  findCellTop(cell: BaseCell<unknown>): number {
    if (cell.row === 0) {
      return this.radius - this.cellSize() / 2;
    }
    const r = cell.row;
    const row = this.grid.getRow(r);
    const theta = (2 * Math.PI) / row.length;

    const centerRadius = (length(r) + length(r + 1)) / 2;
    const centerTheta = (cell.column * theta + (cell.column + 1) * theta) / 2;
    return (
      this.radius + centerRadius * Math.sin(centerTheta) - this.cellSize() / 2
    );
  }

  height(): number {
    return this.radius * 2;
  }
  width(): number {
    return this.radius * 2;
  }

  cellSize() {
    return cellSize;
  }

  calculateWalls(cell: BaseCell<PolarDirections>): Walls<PolarDirections> {
    const bounds = this.cellBounds[cell.coords];

    const walls: Wall[] = [];

    if (cell.coords !== 1) {
      walls.push({
        kind: "arc",
        neighbor: cell.adjacentRefs.inward,

        x1: this.radius,
        y1: this.radius,
        radius: bounds.innerRadius,
        theta1: bounds.thetaCw,
        theta2: bounds.thetaCcw,
        antiClockwise: true,
      });
    }
    if (cell.coords !== 1) {
      walls.push({
        kind: "line",
        neighbor: cell.adjacentRefs.ccw,

        x1: this.radius + bounds.innerRadius * Math.cos(bounds.thetaCcw),
        y1: this.radius + bounds.innerRadius * Math.sin(bounds.thetaCcw),
        x2: this.radius + bounds.outerRadius * Math.cos(bounds.thetaCcw),
        y2: this.radius + bounds.outerRadius * Math.sin(bounds.thetaCcw),
      });
    }

    if (cell.adjacentRefs.outward) {
      let sweep = bounds.thetaCcw;
      for (let outRef of cell.adjacentRefs.outward) {
        const outBounds = this.cellBounds[outRef];

        if (sweep < outBounds.thetaCcw) {
          walls.push({
            kind: "arc",
            neighbor: undefined,
            x1: this.radius,
            y1: this.radius,
            radius: bounds.outerRadius,
            theta1: sweep,
            theta2: outBounds.thetaCcw,
          });
        }
        walls.push({
          kind: "arc",
          neighbor: outRef,
          x1: this.radius,
          y1: this.radius,
          radius: bounds.outerRadius,
          theta1: outBounds.thetaCcw,
          theta2: outBounds.thetaCw,
        });
        sweep = outBounds.thetaCw;
      }
      if (sweep < bounds.thetaCw) {
        walls.push({
          kind: "arc",
          neighbor: undefined,
          x1: this.radius,
          y1: this.radius,
          radius: bounds.outerRadius,
          theta1: sweep,
          theta2: bounds.thetaCw,
        });
      }
    } else {
      walls.push({
        kind: "arc",
        x1: this.radius,
        y1: this.radius,
        radius: bounds.outerRadius,
        theta1: bounds.thetaCcw,
        theta2: bounds.thetaCw,
      } as Wall);
    }

    if (cell.coords !== 1) {
      walls.push({
        kind: "line",
        neighbor: cell.adjacentRefs.cw,
        x1: this.radius + bounds.outerRadius * Math.cos(bounds.thetaCw),
        y1: this.radius + bounds.outerRadius * Math.sin(bounds.thetaCw),
        x2: this.radius + bounds.innerRadius * Math.cos(bounds.thetaCw),
        y2: this.radius + bounds.innerRadius * Math.sin(bounds.thetaCw),
      });
    }
    return {
      cell: cell,
      walls: walls,
    };
  }
}

export default PolarMaze;
