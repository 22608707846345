import deepEqual from "deep-equal";
import {
  BaseCell,
  cellEquals,
  CellRef,
  createCell,
  masked,
  isDeadEnd,
  neighbors,
  link,
  isLinked,
} from "./Cell";
import { MazeKinds } from "../MazeKinds";
import { AllowedNames, getRandomInt, shuffle } from "./utils";

export function* eachRow<T, M extends RowGridDescription>(grid: RowGrid<T, M>) {
  for (let r = 0; r < grid.description.rows; r++) {
    yield grid.getRow(r);
  }
}

export function* eachCell<T, M>(
  grid: BaseGrid<T, M>,
  predicate?: (cell: BaseCell<T>) => boolean
) {
  for (let i = 0; i < grid.size(); i++) {
    const cell = grid.getCellFromRef(i + 1);
    if (!predicate || predicate(cell)) {
      yield cell;
    }
  }
}

export abstract class BaseGrid<T, M> {
  protected grid: BaseCell<T>[];
  readonly description: M;
  private cellsSupplied: boolean;
  private cellId: number = 1;
  readonly gridType: MazeKinds;

  protected newCell(row: number, column: number) {
    if (this.cellsSupplied) {
      const cell = this.grid[this.cellId - 1];
      if (cell.row !== row || cell.column !== column) {
        throw new Error(
          `Error deserializing state, expected row ${row} column ${column} but received ${cell.row} ${cell.column}`
        );
      }
      this.cellId++;
      return cell;
    } else {
      const cell = createCell<T>(row, column, this.cellId++);
      this.grid.push(cell);
      return cell;
    }
  }

  constructor(gridType: MazeKinds, description: M, cells?: BaseCell<T>[]) {
    this.gridType = gridType;
    this.description = description;
    if (cells) {
      this.grid = cells;
      this.cellsSupplied = true;
    } else {
      this.grid = [];
      this.cellsSupplied = false;
    }
  }
  initialize(): void {
    this.prepareGrid();
    if (!this.cellsSupplied) {
      this.configureCells();
    }
  }
  abstract prepareGrid(): void;
  abstract configureCells(): void;
  writeGrid(): string {
    return JSON.stringify(this.toJSON());
  }
  abstract getCenterCell(): BaseCell<T>;

  toJSON(): { gridType: MazeKinds; description: M; cells: BaseCell<T>[] } {
    return {
      gridType: this.gridType,
      description: this.description,
      cells: this.grid,
    };
  }

  getCellFromRef(ref: CellRef): BaseCell<T> {
    return this.grid[ref - 1];
  }

  getNearestCellFromRef(ref: CellRef): BaseCell<T> {
    for (let i = ref - 1; i < this.grid.length; i++) {
      if (!masked(this.grid[i])) {
        return this.grid[i];
      }
    }
    for (let i = ref - 2; i >= 0; i--) {
      if (!masked(this.grid[i])) {
        return this.grid[i];
      }
    }
    debugger;
    throw new Error("No umasked cells");
  }
  randomCell(): BaseCell<T> {
    return this.getNearestCellFromRef(getRandomInt(this.size() - 1) + 1);
  }

  size(): number {
    return this.grid.length;
  }

  maskedCount(): number {
    return this.grid.reduce(
      (count, cell) => (masked(cell) ? count + 1 : count),
      0
    );
  }
  equals(grid: BaseGrid<T, M>): any {
    if (!deepEqual(this.description, grid.description)) {
      return false;
    }
    for (let i = 0; i < this.size(); i++) {
      if (!cellEquals(this.grid[i], grid.grid[i])) {
        return false;
      }
    }

    return true;
  }

  randomCells(): BaseCell<T>[] {
    const array = [...this.grid.filter((e) => !masked(e))];
    shuffle(array);
    return array;
  }

  findDeadEnds(): BaseCell<T>[] {
    return this.grid.filter(isDeadEnd);
  }

  braid(braidPercent: number) {
    //console.log(this.writeGrid());
    const ends = this.findDeadEnds();
    //console.log(ends.map((e) => e.coords));
    for (let cell of ends) {
      const pct = getRandomInt(100);
      if (isDeadEnd(cell) && pct <= braidPercent) {
        const ns = neighbors(cell)
          .filter((r) => !isLinked(cell, r))
          .map((r) => this.getCellFromRef(r));
        if (ns.length > 0) {
          let target = ns.find(isDeadEnd);
          if (!target) {
            target = ns[getRandomInt(ns.length)];
          }
          link(cell, target);
        }
      } else {
        // console.log({
        //   cell: cell,
        //   deadEnd: isDeadEnd(cell),
        //   pct,
        //   braidPercent,
        // });
      }
    }
    // console.log(this.writeGrid());
  }
}

export type RowGridDescription = {
  readonly rows: number;
};

export abstract class RowGrid<T, M extends RowGridDescription> extends BaseGrid<
  T,
  M
> {
  abstract getRow(row: number): BaseCell<T>[];
  abstract getCell(row: number, column: number): BaseCell<T>;
}

export type RowColumnGridDescription = {
  readonly columns: number;
} & RowGridDescription;

export abstract class RowColumnGrid<
  T,
  M extends RowColumnGridDescription = RowColumnGridDescription
> extends BaseGrid<T, M> {
  getRow(row: number): BaseCell<T>[] {
    return this.grid.slice(
      row * this.description.columns,
      (row + 1) * this.description.columns
    );
  }

  getCell(row: number, column: number): BaseCell<T> {
    return this.grid[row * this.description.columns + column];
  }

  getNearestCell(row: number, column: number): BaseCell<T> {
    return this.getNearestCellFromRef(this.getCell(row, column).coords);
  }

  prepareGrid() {
    for (let r = 0; r < this.description.rows; r++) {
      for (let c = 0; c < this.description.columns; c++) {
        this.newCell(r, c);
      }
    }
  }
  maybeGetCell(row: number, column: number) {
    if (
      row < 0 ||
      row >= this.description.rows ||
      column < 0 ||
      column >= this.description.columns
    ) {
      return undefined;
    } else {
      return this.getCell(row, column);
    }
  }

  maybeAssignSingleCell(
    cell: BaseCell<T>,
    dir: AllowedNames<Required<T>, number>,
    row: number,
    column: number
  ) {
    const target = this.maybeGetCell(row, column);
    if (target) {
      // @ts-ignore: this should be fine
      cell.adjacentRefs[dir] = target.coords;
    }
  }
  getCenterCell() {
    return this.getNearestCell(
      Math.floor(this.description.rows / 2),
      Math.floor(this.description.columns / 2)
    );
  }
}
