import { BaseGrid, eachCell } from "../../grids/BaseGrid";
import { BaseCell, CellRef, isLinked, masked } from "../../grids/Cell";
import { Distances } from "../../grids/Dijkstra";
import { MazeRenderer } from "./MazeViewerProps";
import { colors, mapDistancesToIndexedColor } from "./utils";

export type Wall =
  | {
      kind: "line";
      neighbor?: CellRef;
      x1: number;
      y1: number;
      x2: number;
      y2: number;
    }
  | {
      kind: "arc";
      neighbor?: CellRef;
      x1: number;
      y1: number;
      radius: number;
      theta1: number;
      theta2: number;
      antiClockwise?: boolean;
    };

export type Walls<T> = {
  cell: BaseCell<T>;
  walls: Wall[];
};

export const findRightMostPoint = (walls: Wall[]): number => {
  let max = -Infinity;
  for (let wall of walls) {
    if (wall.kind === "line") {
      max = Math.max(max, wall.x1, wall.x2);
    } else if (wall.kind === "arc") {
      const start = wall.antiClockwise ? wall.theta2 : wall.theta1;
      const end = wall.antiClockwise ? wall.theta1 : wall.theta2;

      const normalize = (t: number) => {
        if (t > Math.PI) {
          return 2 * Math.PI - t;
        } else return t;
      };
      if (start > end) {
        max = Math.max(max, wall.radius + wall.x1);
      } else {
        const theta = Math.min(normalize(wall.theta1), normalize(wall.theta2));
        const x = wall.radius * Math.cos(theta) + wall.x1;
        max = Math.max(max, x);
      }
    }
  }

  return max;
};

export const findBottomMostPoint = (walls: Wall[]): number => {
  let max = -Infinity;
  for (let wall of walls) {
    if (wall.kind === "line") {
      max = Math.max(max, wall.y1, wall.y2);
    } else if (wall.kind === "arc") {
      const start = wall.antiClockwise ? wall.theta2 : wall.theta1;
      const end = wall.antiClockwise ? wall.theta1 : wall.theta2;

      if ((start > end || start < Math.PI / 2) && end > Math.PI / 2) {
        max = Math.max(max, wall.radius + wall.x1);
      } else {
        let theta: number | undefined = undefined;
        if (wall.theta1 < Math.PI && wall.theta2 < Math.PI) {
          const normalize = (t: number) => {
            if (t > Math.PI / 2) {
              return Math.PI - t;
            } else return t;
          };
          theta = Math.max(normalize(wall.theta1), normalize(wall.theta2));
        } else if (wall.theta1 < Math.PI) {
          theta = wall.theta1;
        } else if (wall.theta2 < Math.PI) {
          theta = wall.theta2;
        } else {
          const normalize = (t: number) => {
            if (t > (3 * Math.PI) / 2) {
              return 3 * Math.PI - t;
            } else return t;
          };
          theta = Math.min(normalize(wall.theta1), normalize(wall.theta2));
        }
        const x = wall.radius * Math.sin(theta) + wall.y1;
        max = Math.max(max, x);
      }
    }
  }

  return max;
};

export abstract class MazeEngine<G extends BaseGrid<T, unknown>, T>
  implements MazeRenderer<T> {
  grid: G;
  memoizedWalls: Walls<T>[] = [];
  constructor(grid: BaseGrid<unknown, unknown>) {
    this.grid = grid as G;
  }

  abstract calculateWalls(cell: BaseCell<T>): Walls<T>;

  abstract height(): number;
  abstract width(): number;
  abstract findCellLeft(cell: BaseCell<T>): number;
  abstract findCellTop(cell: BaseCell<T>): number;
  abstract cellSize(): number;

  walls(cell: BaseCell<T>): Walls<T> {
    let w = this.memoizedWalls[cell.coords];
    if (!w) {
      w = this.calculateWalls(cell);
      this.memoizedWalls[cell.coords] = w;
    }

    return w;
  }

  drawPath(walls: Walls<T>): Path2D {
    //console.log(walls.cell);

    const p = new Path2D();

    if (walls.walls[0].kind === "line") {
      //console.log(`ctx.moveTo(${walls.walls[0].x1}, ${walls.walls[0].y1});`);
      p.moveTo(walls.walls[0].x1, walls.walls[0].y1);
    }
    for (let wall of walls.walls) {
      if (wall.kind === "line") {
        //console.log(`ctx.lineTo(${wall.x2}, ${wall.y2});`);
        p.lineTo(wall.x2, wall.y2);
      } else if (wall.kind === "arc") {
        //console.log(
        //  `ctx.arc(${wall.x1}, ${wall.y1}, ${wall.radius}, ${wall.theta1}, ${wall.theta2}, ${wall.antiClockwise});`
        //);
        p.arc(
          wall.x1,
          wall.y1,
          wall.radius,
          wall.theta1,
          wall.theta2,
          wall.antiClockwise
        );
      }
    }
    return p;
  }

  drawCellTexture(
    ctx: CanvasRenderingContext2D,
    walls: Walls<T>,
    color: string
  ) {
    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.lineCap = "square";
    const p = this.drawPath(walls);
    ctx.stroke(p);
    ctx.fill(p);

    ctx.lineCap = "square";
    ctx.lineWidth = 2;
    ctx.strokeStyle = "black";
  }

  drawCellWalls(ctx: CanvasRenderingContext2D, walls: Walls<T>) {
    //console.log(`drawing walls for ${walls.cell.coords}`);
    ctx.lineCap = "round";
    ctx.lineWidth = 2;
    ctx.strokeStyle = "black";

    ctx.beginPath();
    if (walls.walls[0].kind === "line") {
      ctx.moveTo(walls.walls[0].x1, walls.walls[0].y1);
    }
    let drawingWalls = false;
    for (let wall of walls.walls) {
      if (!isLinked(walls.cell, wall.neighbor)) {
        if (wall.kind === "line") {
          if (!drawingWalls) {
            drawingWalls = true;
            //console.log(`ctx.moveTo(${wall.x1}, ${wall.y1});`);
            ctx.moveTo(wall.x1, wall.y1);
          }
          //console.log(`ctx.lineTo(${wall.x2}, ${wall.y2});`);
          ctx.lineTo(wall.x2, wall.y2);
        } else if (wall.kind === "arc") {
          if (!drawingWalls) {
            drawingWalls = true;

            const startX = wall.x1 + wall.radius * Math.cos(wall.theta1);
            const startY = wall.y1 + wall.radius * Math.sin(wall.theta1);
            //console.log(`ctx.moveTo(${startX}, ${startY});`);
            ctx.moveTo(startX, startY);
          }
          //console.log(
          //  `ctx.arc(${wall.x1}, ${wall.y1}, ${wall.radius}, ${wall.theta1}, ${wall.theta2}, ${wall.antiClockwise});`
          //);
          ctx.arc(
            wall.x1,
            wall.y1,
            wall.radius,
            wall.theta1,
            wall.theta2,
            wall.antiClockwise
          );
        }
      } else if (drawingWalls) {
        drawingWalls = false;
        //console.log("stroke");
        ctx.stroke();
      }
    }
    if (drawingWalls) {
      //console.log("stroke");
      ctx.stroke();
    }
  }

  render(ctx: CanvasRenderingContext2D, distances?: Distances) {
    this.renderTexture(ctx, distances, { clear: false });
    this.renderGrid(ctx, { clear: false });
  }

  renderTexture(
    ctx: CanvasRenderingContext2D,
    distances?: Distances,
    { clear } = { clear: true }
  ) {
    if (clear) {
      ctx.clearRect(0, 0, this.width(), this.height());
    }
    const palette = distances ? mapDistancesToIndexedColor(distances) : [];

    for (let cell of eachCell(this.grid)) {
      let color = "white";
      if (masked(cell)) {
        color = "black";
      } else if (distances) {
        const distance = distances!.get(cell.coords);

        color = distance !== undefined ? colors[palette[distance]] : "white";
      }

      const walls = this.walls(cell);

      this.drawCellTexture(ctx, walls, color);
    }
  }

  renderGrid(ctx: CanvasRenderingContext2D, { clear } = { clear: true }) {
    if (clear) {
      ctx.clearRect(0, 0, this.width(), this.height());
    }

    for (let cell of eachCell(this.grid)) {
      const walls = this.walls(cell);

      this.drawCellWalls(ctx, walls);
    }
  }

  onClick(
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    selectCell: (cell: CellRef) => void
  ) {
    const target = e.target as HTMLCanvasElement;
    const rect = target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const ctx = target.getContext("2d");
    for (let cell of eachCell(this.grid)) {
      const path = this.drawPath(this.walls(cell));
      if (ctx?.isPointInPath(path, x, y)) {
        selectCell(cell.coords);
        break;
      }
    }
  }
}

export default MazeEngine;
