import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseGrid } from "../grids/BaseGrid";
import { CellRef, masked, BaseCell, neighbors } from "../grids/Cell";
import { reader } from "../grids/Reader";
import { generate } from "./mazeAlgorithms";
import { Highlight, MazeState, PathFindingStateType } from "./mazeTypes";
import { CreateMazeType } from "../grids/CreateMazeType";
import implementations from "../MazeKinds";
import maskCell from "../grids/Masker";
import { AppThunk } from "./store";
import { error } from "./toastSlice";
import { findDistances } from "../grids/Dijkstra";

export function updateGrid(state: MazeState, grid: BaseGrid<unknown, unknown>) {
  if (state.highlight === Highlight.SELECTED_PATH) {
    state.highlight = Highlight.TEXTURE;
  } else if (!state.grid) {
    state.highlight = Highlight.TEXTURE;
  }

  state.grid = grid.toJSON();
  state.endCell = undefined;
  state.startCell = undefined;
  state.pathFinding = PathFindingStateType.NotPathfinding;
  state.gridReadingError = undefined;
}

export function updateGridForMasking(
  state: MazeState,
  grid: BaseGrid<unknown, unknown>
) {
  state.highlight = Highlight.NONE;

  state.grid = grid.toJSON();
  state.endCell = undefined;
  state.startCell = undefined;
  state.pathFinding = PathFindingStateType.SelectingMask;
  state.gridReadingError = undefined;
}

const mazeSlice = createSlice({
  name: "maze",
  initialState: {
    highlight: Highlight.NONE,
    pathFinding: PathFindingStateType.NotPathfinding,
  } as MazeState,
  reducers: {
    setHighlight: (state, action: PayloadAction<Highlight>) => {
      if (state.highlight !== action.payload) {
        state.highlight = action.payload;
      }
    },
    create: (state, action: PayloadAction<CreateMazeType>) => {
      const grid = implementations[action.payload.mazeKind].create(
        action.payload
      );
      if (!action.payload.masking) {
        generate(
          action.payload.mazeType,
          grid,
          action.payload.braid,
          action.payload.braidPercent
        );
        updateGrid(state, grid);
      } else {
        state.pendingCreate = action.payload;
        updateGridForMasking(state, grid);
      }
    },
    read: (state, action: PayloadAction<string>) => {
      try {
        const grid = reader(action.payload);
        updateGrid(state, grid);
      } catch (e) {
        state.gridReadingError = "The file was not in the correct format";
      }
    },
    startPathfinding: (state, action: PayloadAction<void>) => {
      state.pathFinding = PathFindingStateType.SelectingStart;
      state.endCell = undefined;
      state.startCell = undefined;
      if (state.highlight === Highlight.SELECTED_PATH) {
        state.highlight = Highlight.NONE;
      }
    },
    updateSelectedCell: (state, action: PayloadAction<CellRef>) => {
      if (state.pathFinding === PathFindingStateType.SelectingStart) {
        state.startCell = action.payload;
        state.pathFinding = PathFindingStateType.SelectingEnd;
      } else if (state.pathFinding === PathFindingStateType.SelectingEnd) {
        state.endCell = action.payload;
        state.pathFinding = PathFindingStateType.PathCompleted;
        state.highlight = Highlight.SELECTED_PATH;
      } else if (state.pathFinding === PathFindingStateType.SelectingMask) {
        maskCell(state.grid!, action.payload);
      }
    },
    validateAndCompleteMask: (state, action: PayloadAction<void>) => {
      const grid = implementations[state.grid!.gridType].fromState(state.grid!);
      const distances = findDistances<unknown, unknown>(
        grid,
        grid.getCenterCell(),
        (cell) => {
          if (!cell) {
            return [];
          } else {
            return neighbors(cell);
          }
        }
      );

      if (distances.size !== grid.size() - grid.maskedCount()) {
        state.gridReadingError =
          "All areas of the maze must be connected. Please unselect some masked cells";
      } else {
        state.pathFinding = PathFindingStateType.NotPathfinding;
        state.highlight = Highlight.TEXTURE;
        generate(
          state.pendingCreate!.mazeType,
          grid,
          state.pendingCreate!.braid,
          state.pendingCreate!.braidPercent
        );
        updateGrid(state, grid);
        state.pendingCreate = undefined;
      }
    },
  },
});

export const selectCell = (cellRef: CellRef): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    if (
      state.maze.pathFinding !== PathFindingStateType.SelectingMask &&
      masked(state.maze.grid?.cells[cellRef - 1] as BaseCell<unknown>)
    ) {
      dispatch(error("You must select an unmasked cell"));
    } else {
      dispatch(mazeSlice.actions.updateSelectedCell(cellRef));
    }
  };
};

export const completeMask = (): AppThunk => {
  return (dispatch, getState) => {
    dispatch(mazeSlice.actions.validateAndCompleteMask());
    const err = getState().maze.gridReadingError;
    if (err) {
      dispatch(error(err));
    }
  };
};

export const {
  setHighlight,
  create,
  startPathfinding,
  read,
  validateAndCompleteMask,
  updateSelectedCell,
} = mazeSlice.actions;

export default mazeSlice.reducer;
