import { CreateMazeType } from "./grids/CreateMazeType";
import { Grid, Cell } from "./grids/Grid";
import { HexGrid, HexCell } from "./grids/HexGrid";
import { TriangularGrid, TriangularCell } from "./grids/TriangularGrid";
import { PolarGrid, PolarCell } from "./grids/PolarGrid";
import { GridMazeState } from "./grids/GridMazeState";
import RectangularMaze from "./ui/viewers/RectangularMaze";
import PolarMaze from "./ui/viewers/PolarMaze";
import HexMaze from "./ui/viewers/HexMaze";
import TriangularMaze from "./ui/viewers/TriangularMaze";

export enum MazeKinds {
  RECTANGULAR = "rectangular",
  POLAR = "circular",
  HEX = "hex",
  TRIANGULAR = "triangular",
}

const implementations = {
  [MazeKinds.RECTANGULAR]: {
    id: MazeKinds.RECTANGULAR,
    display: "Rectangular",
    create: (p: CreateMazeType) => new Grid(p.rows, p.columns),
    fromState: (state: GridMazeState) =>
      new Grid(
        state.description.rows,
        state.description.columns,
        state.cells as Cell[]
      ),
    needsRows: true,
    needsColumns: true,
    viewer: RectangularMaze,
  },
  [MazeKinds.POLAR]: {
    id: MazeKinds.POLAR,
    display: "Circular",
    create: (p: CreateMazeType) => new PolarGrid(p.rows),
    fromState: (state: GridMazeState) =>
      new PolarGrid(state.description.rows, state.cells as PolarCell[]),
    needsRows: true,
    needsColumns: false,
    viewer: PolarMaze,
  },
  [MazeKinds.HEX]: {
    id: MazeKinds.HEX,
    display: "Hex",
    create: (p: CreateMazeType) => new HexGrid(p.rows, p.columns),
    fromState: (state: GridMazeState) =>
      new HexGrid(
        state.description.rows,
        state.description.columns,
        state.cells as HexCell[]
      ),
    needsRows: true,
    needsColumns: true,
    viewer: HexMaze,
  },
  [MazeKinds.TRIANGULAR]: {
    id: MazeKinds.TRIANGULAR,
    display: "Triangular",
    create: (p: CreateMazeType) => new TriangularGrid(p.rows, p.columns),
    fromState: (state: GridMazeState) =>
      new TriangularGrid(
        state.description.rows,
        state.description.columns,
        state.cells as TriangularCell[]
      ),
    needsRows: true,
    needsColumns: true,
    viewer: TriangularMaze,
  },
};

export default implementations;
