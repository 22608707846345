import { link } from "./Cell";
import { Grid } from "./Grid";
import { getRandomInt } from "./utils";
import { eachRow, BaseGrid } from "./BaseGrid";

export function sidewinder<T, M>(grid: BaseGrid<T, M>) {
  if (grid instanceof Grid) {
    for (let row of eachRow(grid)) {
      let run = [];
      for (let cell of row) {
        run.push(cell);
        const atEasternBoundary = !cell.adjacentRefs.east;
        const atNorthernBoundary = !cell.adjacentRefs.north;
        const shouldCloseOut =
          atEasternBoundary || (!atNorthernBoundary && getRandomInt(2) === 0);
        if (shouldCloseOut) {
          const target = run[getRandomInt(run.length)];
          if (target.adjacentRefs.north) {
            link(target, grid.getCellFromRef(target.adjacentRefs.north));
          }
          run = [];
        } else {
          link(cell, grid.getCellFromRef(cell.adjacentRefs.east!));
        }
      }
    }
  } else {
    throw new Error("Sidewinder is only supported for rectangular grids");
  }
}
